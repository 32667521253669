import React from "react"
import { createClient, Provider } from "urql"
import { useQuery } from "urql"
import store from "store"

const client = createClient({
  url: `${process.env.GATSBY_API_URL}/graphql`,
  fetchOptions: () => {
    return {
      headers: {
        authorization: `Bearer ${store.get("stoken")}`,
      },
    }
  },
})

function QueryBuilder(quizdata) {
  let query = ""
  quizdata.forEach(section => {
    let string =
      section.sectionId +
      `:qbanks (where: { questionId_in: ${JSON.stringify(section.quiz)}}) {
          directions
          question
          options {
            optionId
            option
          }
        }`
    query = query + string
  })
  return query
}

const quizdata = ["QC1184"]

const URQL_QUERY = `
query MyQuery {
  qbanks (where: { questionId_in: ${JSON.stringify(quizdata)}}) {
          directions
          question
          options {
            optionId
            option
          }
        }
}`

const YourRoutes = () => {
  //console.log(quiz)

  const [{ data, fetching, error }] = useQuery({ query: URQL_QUERY })

  if (!fetching) {
    console.log(data)
  }
  if (error) {
    console.log(error.message)
  }

  return (
    <div>
      {/* {data?.qbanks.map((ques, idx) => (
        <div key={idx}>
          <h2>{ques.questionId}</h2>
          <h3>{ques.topic}</h3>
        </div>
      ))} */}
      Test
      <pre className="overflow-y-scroll">{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

const App = () => (
  <Provider value={client}>
    <YourRoutes />
  </Provider>
)

export default App
